import {
  Button,
  Card,
  DescriptionList,
  Dialog,
  Divider,
  Flex,
  GridAlpha,
  Text,
} from '@applyboard/crystal-ui'
import { ThumbUpOutlineIcon } from '@applyboard/ui-icons'
import { useState } from 'react'

type ApplicationDetailCardAcceptDialogProps = Readonly<{
  programName: string
  campusName: string
  intakeTermName: string
  onSubmit: () => void
}>

export function ApplicationDetailCardAcceptDialog(props: ApplicationDetailCardAcceptDialogProps) {
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button intent="primary" width="fill" leadIcon={ThumbUpOutlineIcon}>
          Accept
        </Button>
      </Dialog.Trigger>
      <Dialog.Heading>Make Decision</Dialog.Heading>
      <Dialog.Content>
        <Flex direction="column" gap={4}>
          <Text variant="labelL">Exciting moment! You are about to confirm your acceptance.</Text>
          <Card emphasis="highlighted">
            <DescriptionList variant="free">
              <GridAlpha rowGap={2} columnGap={8}>
                <GridAlpha.Item columnStart={1} rowStart={1}>
                  <DescriptionList.Term>
                    <Text variant="labelL">Program Name</Text>
                  </DescriptionList.Term>
                </GridAlpha.Item>
                <GridAlpha.Item columnStart={2} rowStart={1}>
                  <DescriptionList.Details>
                    <Text variant="bodyM">{props.programName}</Text>
                  </DescriptionList.Details>
                </GridAlpha.Item>
                <GridAlpha.Item columnStart={1} rowStart={2}>
                  <DescriptionList.Term>
                    <Text variant="labelL">Campus</Text>
                  </DescriptionList.Term>
                </GridAlpha.Item>
                <GridAlpha.Item columnStart={2} rowStart={2}>
                  <DescriptionList.Details>
                    <Text variant="bodyM">{props.campusName}</Text>
                  </DescriptionList.Details>
                </GridAlpha.Item>
                <GridAlpha.Item columnStart={1} rowStart={3}>
                  <DescriptionList.Term>
                    <Text variant="labelL">Intake term</Text>
                  </DescriptionList.Term>
                </GridAlpha.Item>
                <GridAlpha.Item columnStart={2} rowStart={3}>
                  <DescriptionList.Details>
                    <Text variant="bodyM">{props.intakeTermName}</Text>
                  </DescriptionList.Details>
                </GridAlpha.Item>
              </GridAlpha>
            </DescriptionList>
          </Card>
        </Flex>
        <Divider />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button
          width="fill"
          intent="primary"
          onClick={() => {
            props.onSubmit()
            setOpen(false)
          }}
        >
          Confirm
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
