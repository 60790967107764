import { ButtonLink } from '@applyboard/crystal-ui'
import { DocumentCheckOutlineIcon } from '@applyboard/ui-icons'

import { ApplicationDetailsFile } from '../types'

type ApplicationDetailCardFileProps = Readonly<ApplicationDetailsFile>

export function ApplicationDetailCardFile(props: ApplicationDetailCardFileProps) {
  return (
    <ButtonLink
      href={props.url}
      mode="override_user_choice_and_open_in_new_tab"
      leadIcon={DocumentCheckOutlineIcon}
      variant="highlighted"
      intent="primary"
      width="fill"
      size="lg"
    >
      {props.name}
    </ButtonLink>
  )
}
