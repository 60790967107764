import { Button, Flex } from '@applyboard/crystal-ui'
import { ApplicationDetailCardAcceptDialog } from './ApplicationDetailCardAcceptDialog'
import {
  ApplicationDetailCardRejectDialog,
  ApplicationDetailCardRejectDialogForm,
} from './ApplicationDetailCardRejectDialog'

type ApplicationDetailCardActionsProps = Readonly<{
  onSubmitRejectDialog?: (values: ApplicationDetailCardRejectDialogForm) => void
  onOfferConditionsClick?: () => void
  acceptDialog?: {
    programName: string
    campusName: string
    intakeTermName: string
    onSubmit: () => void
  }
}>

export function ApplicationDetailCardActions(props: ApplicationDetailCardActionsProps) {
  return (
    <>
      {props.acceptDialog && props.onSubmitRejectDialog ? (
        <Flex gap={2}>
          <Flex.Item basis="50%">
            <ApplicationDetailCardAcceptDialog
              programName={props.acceptDialog.programName}
              campusName={props.acceptDialog.campusName}
              intakeTermName={props.acceptDialog.intakeTermName}
              onSubmit={props.acceptDialog.onSubmit}
            />
          </Flex.Item>
          <Flex.Item basis="50%">
            <ApplicationDetailCardRejectDialog onSubmit={props.onSubmitRejectDialog} />
          </Flex.Item>
        </Flex>
      ) : null}
      {props.onOfferConditionsClick ? (
        <Flex>
          <Button
            intent="primary"
            emphasis="outlined"
            width="fill"
            onClick={props.onOfferConditionsClick}
          >
            Offer Conditions
          </Button>
        </Flex>
      ) : null}
    </>
  )
}
