import { Flex, Heading } from '@applyboard/crystal-ui'
import { DocumentOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'

export function ApplicationDetailHeading() {
  return (
    <Flex gap={2} align="center">
      <IconWrapper aria-hidden>
        <DocumentOutlineIcon />
      </IconWrapper>
      <Heading level={2} variant="titleL">
        Decision Letter
      </Heading>
    </Flex>
  )
}

const IconWrapper = styled.span({
  display: 'flex',
})
