import { Flex } from '@applyboard/crystal-ui'

import { ApplicationDetailCard } from './ApplicationDetailCard'
import { ApplicationDetailHeading } from './ApplicationDetailHeading'

export function ApplicationDetails() {
  return (
    <Flex direction="column" gap={6}>
      <ApplicationDetailHeading />
      <ApplicationDetailCard
        title="Pre-Offer"
        intent="positive"
        status="PENDING"
        issuedOn={new Date()}
        file={{
          name: 'Pre-Offer.pdf',
          url: '#',
        }}
        onSubmitRejectDialog={() => {}}
        onOfferConditionsClick={() => {}}
        helperText="Please download the attached pre-offer letter, review the offer conditions, and respond."
        helperTextIntent="positive"
        acceptDialog={{
          programName: 'Application for Computer Programming and Analysis',
          campusName: 'Downtown',
          intakeTermName: 'Spring 2025',
          onSubmit: () => {},
        }}
      />
      <ApplicationDetailCard
        title="Final Offer"
        intent="positive"
        issuedOn={new Date()}
        file={{
          name: 'Final-Offer.pdf',
          url: '#',
        }}
        onOfferConditionsClick={() => {}}
        helperText="Please download the attached pre-offer letter, review the offer conditions, and respond."
        helperTextIntent="positive"
      />
      <ApplicationDetailCard
        title="Waitlist"
        intent="secondary"
        issuedOn={new Date()}
        file={{
          name: 'Waitlist-Letter.pdf',
          url: '#',
        }}
        onOfferConditionsClick={() => {}}
        helperText="Please download the attached pre-offer letter, review the offer conditions, and respond."
      />
      <ApplicationDetailCard
        title="Decline"
        intent="negative"
        issuedOn={new Date()}
        file={{
          name: 'Declined-Letter.pdf',
          url: '#',
        }}
        helperText="We regret to inform you that, after careful consideration of your application, the school has decided to decline your offer."
        helperTextIntent="negative"
      />
      <ApplicationDetailCard
        title="Pre-Offer"
        intent="positive"
        status="ACCEPTED"
        issuedOn={new Date()}
        statusChangedOn={new Date()}
        file={{
          name: 'Pre-Offer.pdf',
          url: '#',
        }}
        onOfferConditionsClick={() => {}}
      />
      <ApplicationDetailCard
        title="Pre-Offer"
        intent="positive"
        status="REJECTED"
        issuedOn={new Date()}
        statusChangedOn={new Date()}
        file={{
          name: 'Pre-Offer.pdf',
          url: '#',
        }}
        helperText="A decision has been made by you to reject this pre-offer letter."
        helperTextIntent="negative"
      />
    </Flex>
  )
}
